@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i&display=swap');
@import 'node_modules/bootstrap/scss/bootstrap';

@import 'variables';

@import 'mixins';
@import 'layout';

@import 'header';
@import 'footer';

body {
    font-family: 'Roboto', 'sans-serif';
}

main {
    padding: 5rem 0;
}

h1 { font-size: 1.8rem; font-family: 'Roboto', 'sans-serif'; }
h2 { font-size: 1.3rem; font-family: 'Roboto', 'sans-serif'; color: $primary; }
h3 { font-size: 1.1rem; }
h4 { font-size: 1rem; }

section {
    margin-bottom: 2.5rem;
}

article {
    margin-bottom: 2rem;
}
