body {
    >footer {
        background-color: $secondary;
        border-top: solid 2px $primary;
        border-bottom: solid 2px $primary;
        .footer {
            min-height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
}